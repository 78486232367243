import React from 'react'
import Link from 'gatsby-link'
import { GistChat } from './GistChat'
import background from './learn-bg.jpg'

const Header = ({ siteTitle }) => (
  <div
    style={{
      backgroundColor: '#eb3197',
      background: `url(${background})`,
      backgroundSize: 'cover',
      marginBottom: '1.45rem',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        padding: '1.45rem 20px',
      }}
    >
      <h1 style={{ margin: 0, textAlign: 'center' }}>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
    <GistChat />
  </div>
)

export default Header
