import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: 'Learn how to get the best out of Beatflyer',
            },
            { name: 'keywords', content: 'learn, beatflyer' },
          ]}
        />
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 740,
            padding: '60px 20px',
          }}
        >
          {children}
          <footer
            style={{
              textAlign: 'center',
              fontSize: 15,
              lineHeight: '20px',
              borderTop: 'solid 1px #ccc',
              paddingTop: '1.45rem',
              color: '#666',
              marginTop: '1.45rem',
            }}
          >
            If you need further support, or simply want to ask a question,
            please post on our{' '}
            <a
              href="https://www.facebook.com/groups/854273858296665/"
              target="_blank"
              rel="noopener noreferrer"
            >
              user group
            </a>
            .
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
